import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { useState } from 'react';
import SpeciesDetails from './components/SpeciesDetails';
import Signup from './components/SignUp';
import Login from './components/LogIn';

const App = () => {
  const [token, setToken] = useState(localStorage.getItem('token') || null);

  return (
    <Router>
      <Routes>
        {/* Signup Route */}
        <Route path="/signup" element={<Signup />} />
        
        {/* Login Route */}
        <Route path="/login" element={<Login setToken={setToken} />} />
        
        {/* Protected Route */}
        <Route
          path="/"
          element={token ? <SpeciesDetails /> : <Navigate to="/login" />}
        />
      </Routes>
    </Router>
  );
};

export default App;
