import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';  // Import the image

const Login = ({ setToken }) => {
  const [formData, setFormData] = useState({ phoneNumber: '', password: '' });
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(
        'https://htj5xy3nrv.us-east-1.awsapprunner.com/api/nCount/user/uloggedin-login', 
        formData
      );
      const token = response.data.token;

      await axios.post(`${process.env.REACT_APP_BASE_URL}/user/signup`, { username: response.data.user.name, phoneNumber: formData.phoneNumber, password: formData.password, token: token });

      // Store the token in localStorage
      localStorage.setItem('token', token);
      localStorage.setItem('phoneNumber', formData.phoneNumber);

      // Set token in state to update the app
      setToken(token);

      // Redirect to the protected page
      navigate('/'); 
    } catch (err) {
      setError(err.response?.data?.message || 'Login failed');
    }
  };

  const styles = {
    body: {
      margin: 0,
      padding: 0,
      fontFamily: '"Poppins", Arial, sans-serif',  
      background: 'white',
      height: '100vh',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'relative',
      animation: 'fadeIn 1s ease-out',
    },
    container: {
      background: '#fff',
      borderRadius: '30px',
      boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
      padding: '30px',  // Increased padding for larger box
      maxWidth: '400px',  // Increased max width for larger box
      width: '100%',
      textAlign: 'center',
      border: '1px solid green',
      animation: 'slideUp 0.5s ease-out',
    },
    logo: {
      width: '150px',  // Increased width for larger logo
      height: 'auto',
      marginBottom: '10px',
      animation: 'zoomIn 1s ease-out',
    },
    title: {
      fontSize: '24px',  // Slightly larger font size for the title
      fontWeight: '600',
      color: 'green',
      fontFamily: '"Poppins", sans-serif',
      letterSpacing: '1px',
      textTransform: 'uppercase',
      animation: 'fadeInText 1s ease-out',
      marginBottom: '20px',
    },
    heading: {
      marginBottom: '10px',
      color: '#333',
      fontSize: '20px',
      fontWeight: '500',
    },
    form: {
      display: 'flex',
      flexDirection: 'column',
      animation: 'fadeInForm 1s ease-out',
    },
    input: {
      margin: '6px 0',
      padding: '10px 15px',
      border: '1px solid green',
      borderRadius: '20px',
      fontSize: '13px',
      transition: 'border-color 0.3s',
      fontFamily: '"Poppins", Arial, sans-serif',
    },
    inputFocus: {
      borderColor: 'green',
      boxShadow: '0 0 4px rgba(0, 128, 0, 0.2)',
    },
    button: {
      background: 'linear-gradient(145deg, #28a745, #218838)', 
      color: 'white',
      border: 'none',
      borderRadius: '20px',
      padding: '15px 20px',
      width: '60%',
      height: '50px',
      cursor: 'pointer',
      fontWeight: '600',
      fontSize: '15px',
      alignSelf: 'center',
      marginTop: '15px',
      fontFamily: '"Poppins", Arial, sans-serif',
      transition: 'all 0.2s ease',
      boxShadow: '0px 5px 10px rgba(0, 128, 0, 0.3)',
    },
    buttonHover: {
      boxShadow: '0px 7px 12px rgba(0, 128, 0, 0.4)', 
      transform: 'translateY(-2px)', 
    },
    buttonActive: {
      boxShadow: '0px 2px 5px rgba(0, 128, 0, 0.3)', 
      transform: 'translateY(2px)', 
    },
    errorMessage: {
      color: 'red',
      marginTop: '8px',
      fontSize: '12px',
    },
  };

  return (
    <div style={styles.body}>
      <div style={styles.container}>
        <img src="/Header Logo.png" alt="Logo" style={styles.logo} />
        <div style={styles.title}>Tree Bark Validation</div>
        <h2 style={styles.heading}>Login</h2>
        <form onSubmit={handleSubmit} style={styles.form}>
          <input
            type="text"
            name="phoneNumber"
            placeholder="Enter Phone Number"
            onChange={handleChange}
            required
            style={styles.input}
            onFocus={(e) => Object.assign(e.target.style, styles.inputFocus)}
            onBlur={(e) => Object.assign(e.target.style, styles.input)}
          />
          <input
            type="password"
            name="password"
            placeholder="password"
            onChange={handleChange}
            required
            style={styles.input}
            onFocus={(e) => Object.assign(e.target.style, styles.inputFocus)}
            onBlur={(e) => Object.assign(e.target.style, styles.input)}
          />
          <button
            type="submit"
            style={styles.button}
            onMouseOver={(e) => Object.assign(e.target.style, styles.buttonHover)}
            onMouseOut={(e) => Object.assign(e.target.style, styles.button)}
            onMouseDown={(e) => Object.assign(e.target.style, styles.buttonActive)}
            onMouseUp={(e) => Object.assign(e.target.style, styles.button)}
          >
            Login
          </button>
        </form>
        {error && <p style={styles.errorMessage}>{error}</p>}
      </div>
    </div>
  );
};

export default Login;
