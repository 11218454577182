import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "../App.css";


const SpeciesDetails = () => {
  const navigate = useNavigate();
  const [barkData, setBarkData] = useState([]);
  const [dialog, setDialog] = useState(false);
  const [correctName, setCorrectName] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [isSubmitted, setIsSubmitted] = useState(false);

  // Fetch data from the backend for a specific page
  const fetchBarkData = async (page) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/species/get-details?page=${page}`
      );
      const result = response.data;
      if (result.success) {
        setBarkData(result.details.speciesList);
        setCurrentPage(result.details.currentPage);
        setTotalPages(result.details.totalPages);
        fetchValidateData(result.details.speciesList[0]._id);
      }
    } catch (error) {
      console.error("Error fetching bark data:", error);
    }
  };

  const fetchValidateData = async (speciesId) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/species/check-valid-count`,
        { speciesId: speciesId, phoneNumber: localStorage.getItem("phoneNumber") }
      );
      const result = response.data;
      if (result.success) {
        const { validatedLength, userSubmitted } = result.details.validatedData;
        setIsSubmitted(validatedLength >= 5 || userSubmitted);
      }
    } catch (error) {
      console.error("Error fetching bark data:", error);
    }
  };

  useEffect(() => {
    fetchBarkData(currentPage);
  }, [currentPage]);

  const handleNext = () => {
    if (currentPage < totalPages) {
      fetchBarkData(currentPage + 1);
    }
  };

  const handlePrevious = () => {
    if (currentPage > 1) {
      fetchBarkData(currentPage - 1);
    }
  };

  const handleValidate = async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/species/validate`,
        {
          speciesId: barkData[0]._id,
          phoneNumber: localStorage.getItem("phoneNumber"),
        }
      );
      fetchBarkData(currentPage);
    } catch (error) {
      console.error("Error validating:", error);
    }
  };

  const handleInvalidate = async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/species/invalidate`,
        {
          speciesId: barkData[0]._id,
          speciesName: correctName,
          phoneNumber: localStorage.getItem("phoneNumber"),
        }
      );
      fetchBarkData(currentPage);
      setDialog(false);
    } catch (error) {
      console.error("Error invalidating:", error);
    }
  };

  const handleLogout = async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/user/logout`,
        { phoneNumber: localStorage.getItem("phoneNumber") }
      );
      if (response.data.success) {
        localStorage.removeItem("token");
        localStorage.removeItem("phoneNumber");
        navigate("/login");
      }
    } catch (error) {
      console.error("Error logging out:", error);
    }
  };

  // Update form location based on its value
  const getUpdatedFormLocation = (formLocation) => {
    if (formLocation === "Himachal Tree Inventory") {
      return "Himachal Pradesh";
    } else if (formLocation === "Odisha Tree Inventory") {
      return "Odisha";
    }
    return formLocation;  // return as is if no match
  };

  // Format date manually
  const formatDate = (date) => {
    if (!date) return "";
    
    const [day, month, year] = date.split(".");  // Split the date into day, month, year
    const suffix = getDaySuffix(day);  // Get the suffix (st, nd, rd, th)
    const monthNames = [
      "January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"
    ];
    const monthName = monthNames[parseInt(month) - 1];  // Get month name
    
    return `${day}${suffix} ${monthName}, ${year}`;
  };

  // Function to get the day suffix (st, nd, rd, th)
  const getDaySuffix = (day) => {
    const dayInt = parseInt(day, 10);
    if (dayInt >= 11 && dayInt <= 13) {
      return "th";
    }
    switch (dayInt % 10) {
      case 1: return "st";
      case 2: return "nd";
      case 3: return "rd";
      default: return "th";
    }
  };

  return (
    <div style={{
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "100vh",
      textAlign: "center",
      position: "relative",
    }}>
      {/* Logo in the top-left corner with 300px margin from top and size of 300px */}
      <img 
        src="/Header Logo.png"
        alt="Logo" 
        style={{
          position: "absolute",
          top: "30px",  // 300px from the top
          left: "20px",  // 20px from the left
          width: "150px",  // Set the logo width to 300px
          height: "50px", // Set the logo height to 300px
        }}
      />

      <button onClick={handleLogout} style={{
        position: "absolute",
        top: "20px",
        right: "20px",
        zIndex: 10,
        backgroundColor: "#D", // Set the background color to #D
        color: "white",
        padding: "10px 20px",
        border: "none",
        borderRadius: "5px",
        cursor: "pointer"
      }}>
        LOGOUT
      </button>

      {barkData.length > 0 && (
        <div key={currentPage} className="parent-container" style={{ margin: "0 auto", padding: "10px", maxWidth: "600px" }}>
          <div className="imagediv" style={{ marginBottom: "10px", marginRight: 20 }}>
            <img
              src={barkData[0].image_url}
              alt={barkData[0].speciesName}
              style={{ width: "300px" }}
              className="first-img"
            />
            <img
              src={barkData[0].canopy_url}
              alt={barkData[0].fileName}
              style={{ width: "300px" }}
            />
            <div>
              <button onClick={handlePrevious} className="previous-btn">Previous</button>
              <button onClick={handleNext} className="next-btn">Next</button>
            </div>
          </div>

          <div style={{ marginTop: "80px", display: "flex", flexDirection: "column", gap: "15px", alignItems: "center", marginLeft: 20 }}>
            {[
              { label: "Species Name", value: barkData[0]["Species Name"] },
              { label: "Form Location", value: getUpdatedFormLocation(barkData[0]["formLocation"]) }, // Update form location here
              { label: "Species Type", value: barkData[0]["Species Type"] },
              { label: "Submission Date", value: barkData[0]["Submission Date"] } // Format date here
            ].map((detail, index) => (
              <div key={index} style={{
                width: "300px",
                height: "40px",
                border: "2px solid #ccc",
                padding: "10px",
                borderRadius: "30px",
                backgroundColor: "#f0f0f0",
                boxShadow: "0 2px 5px rgba(0, 0, 0, 0.1)",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                transition: "transform 0.2s, box-shadow 0.2s",
              }}
                onMouseEnter={(e) => {
                  e.currentTarget.style.transform = 'scale(1.02)';
                  e.currentTarget.style.boxShadow = '0 8px 20px rgba(0, 0, 0, 0.2)';
                }}
                onMouseLeave={(e) => {
                  e.currentTarget.style.transform = 'scale(1)';
                  e.currentTarget.style.boxShadow = '0 2px 5px rgba(0, 0, 0, 0.1)';
                }}>
                <div>
                  <b>{detail.label}: </b> {detail.value}
                </div>
              </div>
            ))}

            <div className="action-btns" style={{ display: "flex", gap: "10px" }}>
              <button
                disabled={isSubmitted}
                onClick={handleValidate}
                style={{
                  backgroundColor: isSubmitted ? '#d3d3d3' : '#4caf50',
                  color: 'white',
                  padding: '10px 20px',
                  border: 'none',
                  borderRadius: '5px',
                  cursor: isSubmitted ? 'not-allowed' : 'pointer',
                }}
              >
                Validate
              </button>
              <button 
                disabled={isSubmitted} 
                onClick={() => setDialog(true)}
                style={{
                  backgroundColor: isSubmitted ? '#d3d3d3' : '#f44336',
                  color: 'white',
                  padding: '10px 20px',
                  border: 'none',
                  borderRadius: '5px',
                  cursor: isSubmitted ? 'not-allowed' : 'pointer',
                }}
              >
                Invalidate
              </button>
              {dialog && (
                <dialog open style={{ padding: '20px' }}>
                  <h3>Provide Correct Name</h3>
                  <input
                    type="text"
                    value={correctName}
                    onChange={(e) => setCorrectName(e.target.value)}
                  />
                  <button onClick={handleInvalidate}>Submit</button>
                  <button onClick={() => setDialog(false)}>Close</button>
                </dialog>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default SpeciesDetails;
